// main imports
import * as React from "react";
import { graphql } from "gatsby";

// plugin imports
import { Trans, useI18next, useTranslation } from "gatsby-plugin-react-i18next";

// style imports
import "../../index.scss";
import "../menus.scss";

// svg imports

// components imports
import MenuLayout from "../../../components/Layout/MenuLayout.js";
import Seo from "../../../components/Seo/Seo.js";
// import MenuTitle from "../../../components/Menus/MenuTitle";
// import Price from "../../../components/Menus/Price";

// page
export default function IndexPage() {
	const { language } = useI18next();
	const { t } = useTranslation("menuWines");

	return (
		<MenuLayout>
			<Seo lang={language} title={t("wines menu")} />
			<div className="bodyContainer menu wines">
				<section>
					<div>
						<h1>
							<Trans ns="menuWines">wines menu</Trans>.
						</h1>

						<table>
							<thead>
								<tr>
									<th>
										<span>
											#<Trans ns="menuWines">prosecco/sparkling wine</Trans>
										</span>
										.
									</th>
									<th>
										<Trans ns="menuWines">glass</Trans>
									</th>
									<th>
										<Trans ns="menuWines">bottle</Trans>
									</th>
									<th aria-label="4col"></th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>Prosecco Rivalta.</td>
									<td>&nbsp;8.</td>
									<td>45.</td>
								</tr>
								<tr>
									<td>Prosecco Rivalta Rosé.</td>
									<td aria-label="Empty column"></td>
									<td>45.</td>
								</tr>
							</tbody>
						</table>

						<table>
							<thead>
								<tr>
									<th>
										<span>
											#<Trans ns="menuWines">champagne brut</Trans>
										</span>
										.
									</th>
									<th aria-label="2col"></th>
									<th>
										<Trans ns="menuWines">bottle</Trans>
									</th>
									<th aria-label="4col"></th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>EPC Premier Cru.</td>
									<td aria-label="Empty column"></td>
									<td>90.</td>
								</tr>
								<tr>
									<td>Veuve Cliquot.</td>
									<td aria-label="Empty column"></td>
									<td>95.</td>
								</tr>
								<tr>
									<td>Bollinger special cuvée.</td>
									<td aria-label="Empty column"></td>
									<td>115.</td>
								</tr>
								{/* <tr>
									<td>Perrier Joet Grand Brut.</td>
									<td aria-label="Empty column"></td>
									<td>105.</td>
								</tr>
								<tr>
									<td>EPC Magnum 1.5l.</td>
									<td aria-label="Empty column"></td>
									<td>160.</td>
								</tr> */}
							</tbody>
						</table>

						<table>
							<thead>
								<tr>
									<th>
										<span>
											#<Trans ns="menuWines">champagne rosé</Trans>
										</span>
										.
									</th>
									<th aria-label="2col"></th>
									<th>
										<Trans ns="menuWines">bottle</Trans>
									</th>
									<th aria-label="4col"></th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>EPC Rosé Blancs.</td>
									<td aria-label="Empty column"></td>
									<td>90.</td>
								</tr>
								<tr>
									<td>Moët Rosé.</td>
									<td aria-label="Empty column"></td>
									<td>95.</td>
								</tr>
								<tr>
									<td>Bollinger Rosé.</td>
									<td aria-label="Empty column"></td>
									<td>125.</td>
								</tr>
								<tr>
									<td>Ruinart Rose.</td>
									<td aria-label="Empty column"></td>
									<td>135.</td>
								</tr>
								<tr>
									<td>Laurent-Perrier Rosé.</td>
									<td aria-label="Empty column"></td>
									<td>140.</td>
								</tr>
							</tbody>
						</table>

						<table>
							<thead>
								<tr>
									<th>
										<span>
											#<Trans ns="menuWines">champagne blanc de blancs</Trans>
										</span>
										.
									</th>
									<th aria-label="2col"></th>
									<th>
										<Trans ns="menuWines">bottle</Trans>
									</th>
									<th aria-label="4col"></th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>EPC Blanc de Noir.</td>
									<td aria-label="Empty column"></td>
									<td>95.</td>
								</tr>
								<tr>
									<td>EPC Blanc de Blancs.</td>
									<td aria-label="Empty column"></td>
									<td>95.</td>
								</tr>
								<tr>
									<td>Ruinart Blanc de Blancs.</td>
									<td aria-label="Empty column"></td>
									<td>155.</td>
								</tr>
							</tbody>
						</table>

						<h2>
							<Trans ns="menuWines">rose wines</Trans>.
						</h2>

						<table className="countryWines">
							<thead>
								<tr>
									<th>
										<span>
											#<Trans ns="menuWines">france</Trans>
										</span>
										.
									</th>
									<th>
										<Trans ns="menuWines">glass</Trans>
									</th>
									<th>
										<Trans ns="menuWines">bottle</Trans>
									</th>
									<th aria-label="4col"></th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>Studio Miraval.</td>
									<td>5.5</td>
									<td>33.</td>
								</tr>
								{/* <tr>
									<td>Château Saint-Maur Cru Classe.</td>
									<td aria-label="Empty column"></td>
									<td>30.</td>
								</tr> */}
								<tr>
									<td>Miraval.</td>
									<td aria-label="Empty column"></td>
									<td>45.</td>
								</tr>
								<tr>
									<td>Whispering Angel.</td>
									<td aria-label="Empty column"></td>
									<td>50.</td>
								</tr>
								
								{/* <tr>
									<td>Symphonie. Magnum 1.5l.</td>
									<td aria-label="Empty column"></td>
									<td>75.</td>
								</tr>
								<tr>
									<td>Miraval Studio. Magnum 1.5l.</td>
									<td aria-label="Empty column"></td>
									<td>80.</td>
								</tr> */}
								<tr>
									<td>Miraval. Magnum 1.5l.</td>
									<td aria-label="Empty column"></td>
									<td>95.</td>
								</tr>
								<tr>
									<td>Whispering Angel. Magnum 1.5l.</td>
									<td aria-label="Empty column"></td>
									<td>100.</td>
								</tr>
							</tbody>
						</table>
						{/* <table className="countryWines">
							<thead>
								<tr>
									<th>
										<span>
											#<Trans ns="menuWines">italy</Trans>
										</span>
										.
									</th>
									<th aria-label="2col"></th>
									<th>
										<Trans ns="menuWines">bottle</Trans>
									</th>
									<th aria-label="4col"></th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>Costa di Rose.</td>
									<td aria-label="Empty column"></td>
									<td>38.</td>
								</tr>
								<tr>
									<td>Costa di Rose. Magnum 1.5l.</td>
									<td aria-label="Empty column"></td>
									<td>85.</td>
								</tr>
							</tbody>
						</table> */}

						<h2>
							<Trans ns="menuWines">white wines</Trans>.
						</h2>

						<table className="countryWines">
							<thead>
								<tr>
									<th>
										<span>
											#<Trans ns="menuWines">spain</Trans>
										</span>
										.
									</th>
									<th>
										<Trans ns="menuWines">glass</Trans>
									</th>
									<th>
										<Trans ns="menuWines">bottle</Trans>
									</th>
									<th aria-label="4col"></th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>Jose Pariente. Verdejo.</td>
									<td>5.5</td>
									<td>33.</td>
								</tr>
								<tr>
									<td>Jose Pariente. Sauvignon.</td>
									<td>5.5</td>
									<td>33.</td>
								</tr>
								{/* <tr>
									<td>Belondrade Quinta Apolonia. Verdejo.</td>
									<td aria-label="Empty column"></td>
									<td>50.</td>
								</tr> */}
								<tr>
									<td>Belondrade y Lurton. Verdejo.</td>
									<td aria-label="Empty column"></td>
									<td>75.</td>
								</tr>
							</tbody>
						</table>

						<table className="countryWines">
							<thead>
								<tr>
									<th>
										<span>
											#<Trans ns="menuWines">italy</Trans>
										</span>
										.
									</th>
									<th aria-label="2col"></th>
									<th>
										<Trans ns="menuWines">bottle</Trans>
									</th>
									<th aria-label="4col"></th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>Jerman. Pinot Grigio.</td>
									<td aria-label="Empty column"></td>
									<td>45.</td>
								</tr>
							</tbody>
						</table>

						{/* <table className="countryWines">
							<thead>
								<tr>
									<th>
										<span>
											#<Trans ns="menuWines">new zealand</Trans>
										</span>
										.
									</th>
									<th aria-label="2col"></th>
									<th>
										<Trans ns="menuWines">bottle</Trans>
									</th>
									<th aria-label="4col"></th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>Cloudy Bay. Sauvignon Blanc.</td>
									<td aria-label="Empty column"></td>
									<td>75.</td>
								</tr>
								<tr>
									<td>Cloudy Bay. Chardonnay.</td>
									<td aria-label="Empty column"></td>
									<td>80.</td>
								</tr>
							</tbody>
						</table> */}

						<table className="countryWines">
							<thead>
								<tr>
									<th>
										<span>
											#<Trans ns="menuWines">france</Trans>
										</span>
										.
									</th>
									<th aria-label="2col"></th>
									<th>
										<Trans ns="menuWines">bottle</Trans>
									</th>
									<th aria-label="4col"></th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>Miraval.</td>
									<td aria-label="Empty column"></td>
									<td>45.</td>
								</tr>
								<tr>
									<td>Maison Luis Jadot. Chardonnay.</td>
									<td aria-label="Empty column"></td>
									<td>65.</td>
								</tr>
								{/* <tr>
									<td>Louis Latour. Pouilly Fuisse.</td>
									<td aria-label="Empty column"></td>
									<td>85.</td>
								</tr>
								<tr>
									<td>Louis Latour. Chablis.</td>
									<td aria-label="Empty column"></td>
									<td>105.</td>
								</tr> */}
							</tbody>
						</table>
{/* 
						<table className="countryWines">
							<thead>
								<tr>
									<th>
										<span>
											#<Trans ns="menuWines">italy</Trans>
										</span>
										.
									</th>
									<th aria-label="2col"></th>
									<th>
										<Trans ns="menuWines">bottle</Trans>
									</th>
									<th aria-label="4col"></th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>Jermann Pinot Grigio.</td>
									<td aria-label="Empty column"></td>
									<td>45.</td>
								</tr>
							</tbody>
						</table> */}

						{/* <table className="countryWines">
							<thead>
								<tr>
									<th>
										<span>
											#<Trans ns="menuWines">austria</Trans>
										</span>
										.
									</th>
									<th aria-label="2col"></th>
									<th>
										<Trans ns="menuWines">bottle</Trans>
									</th>
									<th aria-label="4col"></th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>Gobelsburg. Grüner Veltliner.</td>
									<td>5.5</td>
									<td>28.</td>
								</tr>
							</tbody>
						</table> */}

						<h2>
							<Trans ns="menuWines">red wines</Trans>.
						</h2>

						<table className="countryWines">
							<thead>
								<tr>
									<th>
										<span>
											#<Trans ns="menuWines">spain</Trans>
										</span>
										.
									</th>
									<th>
										<Trans ns="menuWines">glass</Trans>
									</th>
									<th>
										<Trans ns="menuWines">bottle</Trans>
									</th>
									<th aria-label="4col"></th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>El Olmo Rioja.</td>
									<td>5.5</td>
									<td>33.</td>
								</tr>
								<tr>
									<td>La Pesquera. Ribera del Duero. Crianza.</td>
									<td aria-label="Empty column"></td>
									<td>45.</td>
								</tr>
								<tr>
									<td>
										Pago de Anguix. Tempranillo.
									</td>
									<td aria-label="Empty column"></td>
									<td>50.</td>
								</tr>
								<tr>
									<td>
										Pago de los Capellanes. Ribera del Duero. Crianza 2020.
									</td>
									<td aria-label="Empty column"></td>
									<td>55.</td>
								</tr>
								<tr>
									<td>Vatan. Toro. 2020.</td>
									<td aria-label="Empty column"></td>
									<td>60.</td>
								</tr>
								<tr>
									<td>Psi. 2021.</td>
									<td aria-label="Empty column"></td>
									<td>65.</td>
								</tr>
								<tr>
									<td>Macan Clásico. 2019. Rioja. Crianza.</td>
									<td aria-label="Empty column"></td>
									<td>70.</td>
								</tr>
								<tr>
									<td>Numanthia D.O. Toro. 2019. Tinta de Toro.</td>
									<td aria-label="Empty column"></td>
									<td>80.</td>
								</tr>
								<tr>
									<td>Roda I. Rioja. Reserva 2009./2015./2018.</td>
									<td aria-label="Empty column"></td>
									<td>95.</td>
								</tr>
								<tr>
									<td>Macan. Rioja. Cosecha.</td>
									<td aria-label="Empty column"></td>
									<td>115.</td>
								</tr>
								<tr>
									<td>Pintia. Toro. 2018. Tempranillo.</td>
									<td aria-label="Empty column"></td>
									<td>115.</td>
								</tr>
								
							</tbody>
						</table>

						<table className="countryWines">
							<thead>
								<tr>
									<th>
										<span>
											#<Trans ns="menuWines">italy</Trans>
										</span>
										.
									</th>
									<th aria-label="2col"></th>
									<th>
										<Trans ns="menuWines">bottle</Trans>
									</th>
									<th aria-label="4col"></th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>Tommsi Amarone della Valpolicella Classico. 2018. Corvina, Rondinella, Corvinone.</td>
									<td aria-label="Empty column"></td>
									<td>85.</td>
								</tr>
								<tr>
									<td>Tignanello. 2019. Sangiovese, Cabernet, Sauvignon,Cabernet franc.</td>
									<td aria-label="Empty column"></td>
									<td>295.</td>
								</tr>
							</tbody>
						</table>

						{/* <table className="countryWines">
							<thead>
								<tr>
									<th>
										<span>
											#<Trans ns="menuWines">austria</Trans>
										</span>
										.
									</th>
									<th aria-label="2col"></th>
									<th>
										<Trans ns="menuWines">bottle</Trans>
									</th>
									<th aria-label="4col"></th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>Gobelsburg. Zweigelt. Austria.</td>
									<td>5.5</td>
									<td>33.</td>
								</tr>
							</tbody>
						</table> */}
					</div>
				</section>
			</div>
		</MenuLayout>
	);
}

// graphql query
export const query = graphql`
	query ($language: String!) {
		locales: allLocale(filter: { language: { eq: $language } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
	}
`;
