// main imports
import * as React from "react";
import { graphql } from "gatsby";

// plugin imports
import { Trans, useI18next, useTranslation } from "gatsby-plugin-react-i18next";

// style imports
import "../../index.scss";
import "../menus.scss";

// svg imports

// components imports
import MenuLayout from "../../../components/Layout/MenuLayout.js";
import Seo from "../../../components/Seo/Seo.js";

// page
export default function IndexPage() {
	const { language } = useI18next();
	const { t } = useTranslation("menuDrinks");

	return (
		<MenuLayout>
			<Seo lang={language} title={t("spirits menu")} />
			<div className="bodyContainer menu drinks">
				<section>
					<div>
						<h1>
							<Trans ns="menuDrinks">spirits menu</Trans>.
						</h1>

						<p className="explanation">
							*<Trans ns="menuDrinks">soft drinks included</Trans>
						</p>

						<table>
							<thead>
								<tr>
									<th>
										<span>
											#<Trans ns="menuDrinks">vodka</Trans>
										</span>
										.
									</th>
									<th>
										<Trans ns="menuDrinks">glass</Trans>*
									</th>
									<th>
										<Trans ns="menuDrinks">bottle</Trans>
									</th>
									<th aria-label="4col"></th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>Absolut.</td>
									<td>11.</td>
									<td>125.</td>
									<td>0.70l</td>
								</tr>
								<tr>
									<td>Ciroc.</td>
									<td>13.</td>
									<td>145.</td>
									<td>0.70l</td>
								</tr>
								<tr>
									<td>Belvedere.</td>
									<td>13.</td>
									<td>145.</td>
									<td>0.70l</td>
								</tr>
								<tr>
									<td>Belvedere organic Lemon & Basil.</td>
									<td>13.</td>
									<td>125.</td>
									<td>0.70l</td>
								</tr>
								<tr>
									<td>Belvedere organic Black Berries & Lemon.</td>
									<td>13.</td>
									<td>125.</td>
									<td>0.70l</td>
								</tr>
								<tr>
									<td>Belvedere organic Pear & Ginger.</td>
									<td>13.</td>
									<td>125.</td>
									<td>0.70l</td>
								</tr>
								<tr>
									<td>Saline Ibiza.</td>
									<td>13.</td>
									<td>125.</td>
									<td>0.70l</td>
								</tr>
								<tr>
									<td>Grey Goose.</td>
									<td>13.</td>
									<td>145.</td>
									<td>0.70l</td>
								</tr>
								<tr>
									<td>Belvedere 10.</td>
									<td>25.</td>
									<td>350.</td>
									<td>0.70l</td>
								</tr>
							</tbody>
						</table>

						<p className="explanation">
							*<Trans ns="menuDrinks">soft drinks included</Trans>
						</p>
						<table>
							<thead>
								<tr>
									<th>
										<span>
											#<Trans ns="menuDrinks">gin</Trans>
										</span>
										.
									</th>
									<th>
										<Trans ns="menuDrinks">glass</Trans>*
									</th>
									<th>
										<Trans ns="menuDrinks">bottle</Trans>
									</th>
									<th aria-label="4col"></th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>Puerto de Indias.</td>
									<td>11.</td>
									<td>125.</td>
									<td>0.70l</td>
								</tr>
								<tr>
									<td>Beefeater.</td>
									<td>11.</td>
									<td>125.</td>
									<td>0.70l</td>
								</tr>
								<tr>
									<td>Bombay Bramble.</td>
									<td>11.</td>
									<td>125.</td>
									<td>0.70l</td>
								</tr>
								<tr>
									<td>Bombay Saphire.</td>
									<td>11.</td>
									<td>125.</td>
									<td>0.70l</td>
								</tr>
								<tr>
									<td>Tanqueray.</td>
									<td>11.</td>
									<td>125.</td>
									<td>0.70l</td>
								</tr>
								<tr>
									<td>Tanqueray 10.</td>
									<td>13.</td>
									<td>145.</td>
									<td>0.70l</td>
								</tr>
								<tr>
									<td>Tanqueray Sevilla.</td>
									<td>13.</td>
									<td>145.</td>
									<td>0.70l</td>
								</tr>
								<tr>
									<td>Hendricks.</td>
									<td>13.</td>
									<td>145.</td>
									<td>0.70l</td>
								</tr>
								<tr>
									<td>Monkey 47.</td>
									<td>13.</td>
									<td>125.</td>
									<td>0.50l</td>
								</tr>
								<tr>
									<td>Le Tribute.</td>
									<td>13.</td>
									<td>125.</td>
									<td>0.70l</td>
								</tr>
								<tr>
									<td>The Botanist 22.</td>
									<td>13.</td>
									<td>125.</td>
									<td>0.70l</td>
								</tr>
								<tr>
									<td>Malfy Pink.</td>
									<td>13.</td>
									<td>125.</td>
									<td>0.70l</td>
								</tr>
								<tr>
									<td>Malfy Original.</td>
									<td>13.</td>
									<td>125.</td>
									<td>0.70l</td>
								</tr>
								<tr>
									<td>Martin Miller's.</td>
									<td>13.</td>
									<td>125.</td>
									<td>0.70l</td>
								</tr>
								<tr>
									<td>Gardener's Gin.</td>
									<td>13.</td>
									<td>125.</td>
									<td>0.70l</td>
								</tr>
							</tbody>
						</table>

						<p className="explanation">
							*<Trans ns="menuDrinks">soft drinks included</Trans>
						</p>
						<table>
							<thead>
								<tr>
									<th>
										<span>
											#<Trans ns="menuDrinks">whiskey / bourbon / scotch</Trans>
										</span>
										.
									</th>
									<th>
										<Trans ns="menuDrinks">glass</Trans>*
									</th>
									<th>
										<Trans ns="menuDrinks">bottle</Trans>
									</th>
									<th aria-label="4col"></th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>Southern Comfort.</td>
									<td>11.</td>
									<td>125.</td>
									<td>0.70l</td>
								</tr>
								<tr>
									<td>Ballantine's.</td>
									<td>11.</td>
									<td>125.</td>
									<td>0.70l</td>
								</tr>

								<tr>
									<td>Jack Daniel's.</td>
									<td>11.</td>
									<td>125.</td>
									<td>0.70l</td>
								</tr>
								<tr>
									<td>Makers Mark.</td>
									<td>13.</td>
									<td>145.</td>
									<td>0.70l</td>
								</tr>
								<tr>
									<td>
										Glenmorangie 10 <Trans ns="menuDrinks">years</Trans>.
									</td>
									<td>13.</td>
									<td>145.</td>
									<td>0.70l</td>
								</tr>
								<tr>
									<td>Chivas 12.</td>
									<td>11.</td>
									<td>125.</td>
									<td>0.70l</td>
								</tr>
								<tr>
									<td>
										Chivas Regal 18 <Trans ns="menuDrinks">years</Trans>.
									</td>
									<td>13.</td>
									<td>145.</td>
									<td>0.70l</td>
								</tr>
								<tr>
									<td>Chivas Mizunara.</td>
									<td>15.</td>
									<td>200.</td>
									<td>0.70l</td>
								</tr>
								<tr>
									<td>
										Macallan Double Cask 12 <Trans ns="menuDrinks">years</Trans>
										.
									</td>
									<td>15.</td>
									<td>200.</td>
									<td>0.70</td>
								</tr>
								<tr>
									<td>Johnny Walker Black.</td>
									<td>13.</td>
									<td>145.</td>
									<td>0.70l</td>
								</tr>
								<tr>
									<td>Johnny Walker Gold.</td>
									<td>15.</td>
									<td>195.</td>
									<td>0.70l</td>
								</tr>
								<tr>
									<td>Johnny Walker Blue.</td>
									<td>30.</td>
									<td>395.</td>
									<td>0.70l</td>
								</tr>
								<tr>
									<td>Michael Couvreur.</td>
									<td>25.</td>
									<td>350.</td>
									<td>0.70l</td>
								</tr>
							</tbody>
						</table>

						<p className="explanation">
							*<Trans ns="menuDrinks">soft drinks included</Trans>
						</p>
						<table>
							<thead>
								<tr>
									<th>
										<span>
											#<Trans ns="menuDrinks">tequila</Trans>
										</span>
										.
									</th>
									<th>
										<Trans ns="menuDrinks">glass</Trans>*
									</th>
									<th>
										<Trans ns="menuDrinks">bottle</Trans>
									</th>
									<th aria-label="4col"></th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>Cruzplata blanco.</td>
									<td>11.</td>
									<td>125.</td>
									<td>0.70l</td>
								</tr>
								<tr>
									<td>Cruzplata reposado.</td>
									<td>11.</td>
									<td>125.</td>
									<td>0.70l</td>
								</tr>
								<tr>
									<td>Curado Espadín.</td>
									<td>11.</td>
									<td aria-label="Empty column"></td>
									<td>0.70l</td>
								</tr>
								<tr>
									<td>La Cofradia.</td>
									<td>13.</td>
									<td aria-label="Empty column"></td>
									<td>0.70l</td>
								</tr>
								<tr>
									<td>Mezcal Contraluz Cristalino.</td>
									<td>13.</td>
									<td aria-label="Empty column"></td>
									<td>0.70l</td>
								</tr>
								<tr>
									<td>Mezcal Le Tribute.</td>
									<td>13.</td>
									<td aria-label="Empty column"></td>
									<td>0.70l</td>
								</tr>
								<tr>
									<td>Don Julio blanco.</td>
									<td>13.</td>
									<td>145.</td>
									<td>0.70l</td>
								</tr>
								<tr>
									<td>Don Julio reposado.</td>
									<td>13.</td>
									<td>145.</td>
									<td>0.70l</td>
								</tr>
								<tr>
									<td>Don Julio 1942.</td>
									<td>25.</td>
									<td>350.</td>
									<td>0.70l</td>
								</tr>
								<tr>
									<td>Komos añejo.</td>
									<td>25.</td>
									<td>350.</td>
									<td>0.70l</td>
								</tr>
								<tr>
									<td>Komos rosado.</td>
									<td>25.</td>
									<td>350.</td>
									<td>0.70l</td>
								</tr>
								<tr>
									<td>Patrón El Cielo.</td>
									<td>25.</td>
									<td>350.</td>
									<td>0.70l</td>
								</tr>
								<tr>
									<td>Clase Azul reposado.</td>
									<td>30.</td>
									<td>395.</td>
									<td>0.70l</td>
								</tr>
							</tbody>
						</table>

						<p className="explanation">
							*<Trans ns="menuDrinks">soft drinks included</Trans>
						</p>
						<table>
							<thead>
								<tr>
									<th>
										<span>
											#<Trans ns="menuDrinks">ron</Trans>
										</span>
										.
									</th>
									<th>
										<Trans ns="menuDrinks">glass</Trans>*
									</th>
									<th>
										<Trans ns="menuDrinks">bottle</Trans>
									</th>
									<th aria-label="4col"></th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>
										Santísima Trinidad 3 <Trans ns="menuDrinks">years</Trans>.
									</td>
									<td>11.</td>
									<td>125.</td>
									<td>0.70l</td>
								</tr>
								<tr>
									<td>
										Santísima Trinidad 7 <Trans ns="menuDrinks">years</Trans>.
									</td>
									<td>13.</td>
									<td>125.</td>
									<td>0.70l</td>
								</tr>
								<tr>
									<td>
										Bacardi <Trans ns="menuDrinks">White</Trans>.
									</td>
									<td>11.</td>
									<td>125.</td>
									<td>0.70l</td>
								</tr>
								<tr>
									<td>Santiago de Cuba Añejo.</td>
									<td>11.</td>
									<td>110</td>
									<td>0.70l</td>
								</tr>
								<tr>
									<td>Santiago de Cuba Xtra Añejo.</td>
									<td>13.</td>
									<td>125.</td>
									<td>0.70l</td>
								</tr>
								<tr>
									<td>Santa Teresa 1796.</td>
									<td>13.</td>
									<td>125.</td>
									<td>0.70l</td>
								</tr>
								<tr>
									<td>Diplomatico Reserva.</td>
									<td>13.</td>
									<td>125.</td>
									<td>0.70l</td>
								</tr>

								<tr>
									<td>Pampero Aniversario.</td>
									<td>13.</td>
									<td>145.</td>
									<td>0.70l</td>
								</tr>
								<tr>
									<td>
										Bacardi 8 <Trans ns="menuDrinks">years</Trans>.
									</td>
									<td>13.</td>
									<td>125.</td>
									<td>0.70l</td>
								</tr>
								<tr>
									<td>
										Havana Club 3 <Trans ns="menuDrinks">years</Trans>.
									</td>
									<td>11.</td>
									<td>125.</td>
									<td>0.70l</td>
								</tr>
								<tr>
									<td>
										Havana Club 7 <Trans ns="menuDrinks">years</Trans>.
									</td>
									<td>13.</td>
									<td>125.</td>
									<td>0.70l</td>
								</tr>
								<tr>
									<td>Havana Selection Maestro.</td>
									<td>15.</td>
									<td>195.</td>
									<td>0.70l</td>
								</tr>
								<tr>
									<td>Zacapa 23.</td>
									<td>15.</td>
									<td>195.</td>
									<td>0.70l</td>
								</tr>
							</tbody>
						</table>

						<p className="explanation">
							*<Trans ns="menuDrinks">soft drinks included</Trans>
						</p>
						<table>
							<thead>
								<tr>
									<th>
										<span>
											#<Trans ns="menuDrinks">cognac</Trans>
										</span>
										.
									</th>
									<th>
										<Trans ns="menuDrinks">glass</Trans>*
									</th>
									<th>
										<Trans ns="menuDrinks">bottle</Trans>
									</th>
									<th aria-label="4col"></th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>Metaxa.</td>
									<td>15.</td>
									<td aria-label="Empty column"></td>
									<td>0.70l</td>
								</tr>
								<tr>
									<td>Hennessy VS.</td>
									<td>13.</td>
									<td>145.</td>
									<td>0.70l</td>
								</tr>
								<tr>
									<td>Hennessy XO.</td>
									<td>30.</td>
									<td>395.</td>
									<td>0.70l</td>
								</tr>
								<tr>
									<td>Hennessy Paradis.</td>
									<td aria-label="Empty column"></td>
									<td>1950.</td>
									<td>0.70l</td>
								</tr>
							</tbody>
						</table>

						<p className="explanation">
							*<Trans ns="menuDrinks">soft drinks included</Trans>
						</p>
						<table>
							<thead>
								<tr>
									<th>
										<span>
											#
											<Trans ns="menuDrinks">liqueur / bitter / vermouth</Trans>
										</span>
										.
									</th>
									<th>
										<Trans ns="menuDrinks">glass</Trans>*
									</th>
									<th>
										<Trans ns="menuDrinks">bottle</Trans>
									</th>
									<th aria-label="4col"></th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>Baileys 4cl.</td>
									<td>&nbsp;8.</td>
									<td>125.</td>
									<td>0.70l</td>
								</tr>
								<tr>
									<td>Kahlua 4cl.</td>
									<td>&nbsp;8.</td>
									<td>125.</td>
									<td>0.70l</td>
								</tr>
								<tr>
									<td>Cointreau 4cl.</td>
									<td>&nbsp;8.</td>
									<td>125.</td>
									<td>0.70l</td>
								</tr>
								<tr>
									<td>Ramazotti 4cl.</td>
									<td>&nbsp;8.</td>
									<td>125.</td>
									<td>0.70l</td>
								</tr>
								<tr>
									<td>Malibu 4cl.</td>
									<td>&nbsp;8.</td>
									<td>125.</td>
									<td>0.70l</td>
								</tr>
								<tr>
									<td>Jägermeister 4cl.</td>
									<td>&nbsp;8.</td>
									<td>125.</td>
									<td>0.70l</td>
								</tr>
								<tr>
									<td>Belsazar Dry.</td>
									<td>11.</td>
									<td>125.</td>
									<td>0.70l</td>
								</tr>
								<tr>
									<td>Belsazar Rose.</td>
									<td>11.</td>
									<td>125.</td>
									<td>0.70l</td>
								</tr>
								<tr>
									<td>Belsazar Red.</td>
									<td>11.</td>
									<td>125.</td>
									<td>0.70l</td>
								</tr>
								<tr>
									<td>Belsazar White.</td>
									<td>11.</td>
									<td>125.</td>
									<td>0.70l</td>
								</tr>
								<tr>
									<td>Pisco.</td>
									<td>13.</td>
									<td>125.</td>
									<td>0.70l</td>
								</tr>
								<tr>
									<td>Santo Grau Cachaça.</td>
									<td>13.</td>
									<td>145.</td>
									<td>0.70l</td>
								</tr>
								<tr>
									<td>Capucana envejecida Cachaça.</td>
									<td>13.</td>
									<td aria-label="Empty column"></td>
									<td>0.70l</td>
								</tr>
								<tr>
									<td>Pimms.</td>
									<td>11.</td>
									<td>125.</td>
									<td>0.70l</td>
								</tr>
								<tr>
									<td>Campari.</td>
									<td>11.</td>
									<td>125.</td>
									<td>0.70l</td>
								</tr>
								<tr>
									<td>Aperol.</td>
									<td>11.</td>
									<td>125.</td>
									<td>0.70l</td>
								</tr>
								<tr>
									<td>Pernod.</td>
									<td>&nbsp;8.</td>
									<td>125.</td>
									<td>0.70l</td>
								</tr>
								<tr>
									<td>Amaretto.</td>
									<td>11.</td>
									<td>125.</td>
									<td>0.70l</td>
								</tr>
								<tr>
									<td>Marille 2cl.</td>
									<td>&nbsp;7.</td>
									<td>145.</td>
									<td>0.35l</td>
								</tr>
								<tr>
									<td>Obstler 2cl.</td>
									<td>&nbsp;7.</td>
									<td>145.</td>
									<td>0.35l</td>
								</tr>
								<tr>
									<td>Zwetschke 2cl.</td>
									<td>&nbsp;7.</td>
									<td>145.</td>
									<td>0.35l</td>
								</tr>
								<tr>
									<td>Grappa Nonino 2cl.</td>
									<td>&nbsp;5.</td>
									<td>145.</td>
									<td>0.70l</td>
								</tr>
								<tr>
									<td>Sambucca 4cl.</td>
									<td>&nbsp;8.</td>
									<td>125.</td>
									<td>0.70l</td>
								</tr>
								<tr>
									<td>Machaquito 4cl.</td>
									<td>&nbsp;8.</td>
									<td>125.</td>
									<td>0.70l</td>
								</tr>
							</tbody>
						</table>
					</div>
				</section>
			</div>
		</MenuLayout>
	);
}

// graphql query
export const query = graphql`
	query ($language: String!) {
		locales: allLocale(filter: { language: { eq: $language } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
	}
`;
