// main imports
import * as React from "react";
import { graphql } from "gatsby";

// plugin imports
import { Trans, useI18next, useTranslation } from "gatsby-plugin-react-i18next";

// style imports
import "../../index.scss";
import "../menus.scss";

// svg imports
import BossaLogo from "../../../svg/logo.svg";

// components imports
import MenuLayout from "../../../components/Layout/MenuLayout.js";
import Seo from "../../../components/Seo/Seo.js";
import MenuTitle from "../../../components/Menus/MenuTitle";
import Allergens from "../../../components/Menus/Allergens";
import Price from "../../../components/Menus/Price";
import Description from "../../../components/Menus/Description";
import MenuCredits from "../../../components/Menus/MenuCredits";
import MenuDessert from "../../../components/Menus/MenuDessert";
// import MenuBites from "../../../components/Menus/MenuBites";

// page
export default function IndexPage() {
	const { language } = useI18next();
	const { t } = useTranslation("menuLunch");

	return (
		<MenuLayout>
			<Seo lang={language} title={t("lunch menu")} />
			<div className="bodyContainer menu">
				<section>
					<div>
						{/* <h1>
							<Trans ns="menuLunch">lunch menu</Trans>.
						</h1> */}
						<BossaLogo className="menuLogo" />
						<p>
							<Trans ns="menuLunch">
								BOSSA not only stands for the enjoyable sharing and joint
								experience of excellent cuisine, but also for the time we spend
								together.
							</Trans>
						</p>
						<p>
							<Trans ns="menuLunch">
								The menu is adjusted seasonally and sometimes daily by our chef
								de cuisine to offer you the best quality.
							</Trans>
						</p>
						<p>
							<Trans ns="menuLunch">
								We rely on regional products supplemented with the best products
								from around the world.
							</Trans>
						</p>
						<p>
							<Trans ns="menuLunch">
								A "bossa share." - is what we call an apéro, dish, tapas created
								around one main ingredient and served in the middle of your
								table.
							</Trans>
						</p>
						<p>
							<Trans ns="menuLunch">
								Create your sharing experience for your whole table by choosing
								your favourite bossa shares.
							</Trans>
						</p>
						<p>
							<Trans ns="menuLunch">
								Our aim is to create a unique interactive experience to round
								off your day!
							</Trans>
						</p>
						<p className="introSignature">bossa. obviously.</p>

						<MenuTitle
							bold={t("bossa to share")}
							descr="authentic. experimental"
						/>
						<p>
							<Price>15.</Price>
							<Trans ns="menuLunch">bossa</Trans>
							<span className="small">
								<Trans ns="menuLunch"> salad</Trans>.
							</span>
							<Description>
								<Trans ns="menuLunch">
								lettuce. bacon. crouton. soft boiled egg. caesar dressing. parmesan
								</Trans>
								.<Allergens>1.3.7.10.</Allergens>
								<br />
								<span className="regular">
									<Trans ns="menuLunch">add on</Trans>.
								</span>
								<br />
								<Price>+5.</Price>
								<Trans ns="menuLunch">chicken</Trans>.
							</Description>
						</p>
						<p>
							<Price>18.</Price>
							<Trans ns="menuLunch">tuna tartare sando</Trans>.
							<span className="small">
								<Trans ns="menuLunch"></Trans>
							</span>
							<Description>
								<Trans ns="menuLunch">
								tuna tartare. kimchi sauce. sesame oil. soja sauce
								</Trans>
								.<Allergens>1.3.6.10.11.</Allergens>
							</Description>
						</p>
						<p>
							<Price>13.</Price>
							<Trans ns="menuLunch">croque monsieur</Trans>.
							<Description>
								<Trans ns="menuLunch">
								sliced bread. comte cheese. ham. bechamel. chives
								</Trans>
								.<Allergens>1.7.</Allergens>
							</Description>
						</p>
						<p>
							<Price>16.</Price>
							<Trans ns="menuLunch">crabs</Trans>.
							<Description>
								<Trans ns="menuLunch">
								brioche. avocado. crabs. coriander. green pepper. mayonnaise
								</Trans>
								.<Allergens>1.2.3.7.10.</Allergens>
							</Description>
						</p>
						<p>
							<Price>15.</Price>
							<Trans ns="menuLunch">avocado</Trans>.
							<Description>
								<Trans ns="menuLunch">
								avocado. coriander pesto. vinaigrette. feta
								</Trans>
								.<Allergens>3.4.11.</Allergens>
							</Description>
						</p>
						<p>
							<Price>11.</Price>
							<Trans ns="menuLunch">soup of the day</Trans>.
						</p>

						<MenuTitle bold={t("bossa pasta")} descr="a moda">
							{/*<Price>25.</Price>*/}
						</MenuTitle>
						{/*<Description>
							<Trans ns="menuLunch">
							tagliatelle. salted lemon. tomato. burrata
							</Trans>
							.<Allergens>1.3.6.7.</Allergens>
						</Description>*/}
						<p>
							<Price>23.</Price>
							<Trans ns="menuLunch">homemade agnolotti</Trans>.
							<Description>
								<Trans ns="menuLunch">stuffed with truffle pecorino</Trans>.
								<Allergens>1.3.7.</Allergens>
								<br />
								<span className="regular">
									<Trans ns="menuLunch">add on</Trans>.
								</span>
								<br />
								<Price>+5.</Price>
								<Trans ns="menuLunch">truffle</Trans>.
							</Description>
						</p>

						<p>
							<Price>23.</Price>
							<Trans ns="menuLunch">al ragu</Trans>.
							<Description>
								<Trans ns="menuLunch">paccheri. beef cheek. tomato sauce. parmesan. sage</Trans>.
								<Allergens>1.3.</Allergens>
							</Description>
						</p>

						<MenuTitle bold={t("schnitzel")} descr="sabor. austríaco" />
						<Description>
							<Trans ns="menuLunch">
								deep fried in lard. served with potato salad. lingonberry
								marmalade & lemon.
							</Trans>
							<Allergens>10.</Allergens>
						</Description>

						<p>
							<Trans ns="menuLunch">choose</Trans>.
							<Description>
								<Price>28.</Price>
								<span className="regular">
									<Trans ns="menuLunch">veal</Trans>.
								</span>
								<Allergens>1.3.</Allergens>
								<br />
								<Price>26.</Price>
								<span className="regular">
									<Trans ns="menuLunch">chicken</Trans>.
								</span>
								<Allergens>1.3.</Allergens>
							</Description>
						</p>

						<MenuTitle bold={t("mar & terra")} />

						<p>
							<Price>25.</Price>
							<Trans ns="menuLunch">poultry</Trans>.
							<Description>
								<Trans ns="menuLunch">
								chicken. creamy morel sauce. mashed potatoes. jus
								</Trans>
								.<Allergens>7.</Allergens>
							</Description>
						</p>
						<p>
							<Price>17.</Price>
							<Trans ns="menuLunch">smash burger</Trans>.
							<Description>
								<Trans ns="menuLunch">
								brioche. beef. cheddar cheese. sauce. pickles. fries
								</Trans>
								.<Allergens>1.3.7.10.</Allergens>
							</Description>
						</p>
						<p>
							<Price>24.</Price>
							<Trans ns="menuLunch">salmon bowl</Trans>.
							<Description>
								<Trans ns="menuLunch">
								salmon teriyaki. rice. wakame. cherry tomato. kimchi mayonnaise. avocado. edamame. red cabbage
								</Trans>
								.<Allergens>3.6.10.11.</Allergens>
								<br />
								<span className="regular">
									<Trans ns="menuLunch">add on</Trans>.
								</span>
								<br />
								<Price>+5.</Price>
								<Trans ns="menuLunch">fries</Trans>.<Allergens>1.</Allergens>
								<br />
								<Price>+5.</Price>
								<Trans ns="menuLunch">house salad</Trans>.
								<br />
								<Price>+5.</Price>
								<Trans ns="menuLunch">broccoli</Trans>.
								<br />
								<Price>+5.</Price>
								<Trans ns="menuLunch">mashed potatoes</Trans>.
								<Allergens>7.</Allergens>
								<br />
								<Price>+5.</Price>
								<Trans ns="menuLunch">garlic butter rice</Trans>.
								<Allergens>1.7.</Allergens>
							</Description>
						</p>

						{/* <MenuTitle
							bold={t("bossa taste")}
							descr="authentic. experimental"
						/>
						<p>
							<Price>23.</Price>
							<Trans ns="menuLunch">chicken</Trans>.
							<Description>
								<Trans ns="menuLunch">
									teriyaki. shimeji. asparagus. sweet potato
								</Trans>
								.<Allergens>1.3.6.9.10.11.</Allergens>
							</Description>
						</p>
						<p>
							<Price>23.</Price>
							<Trans ns="menuLunch">red snapper</Trans>.
							<Description>
								<Trans ns="menuLunch">white / green bean. spring herbs</Trans>.
								<Allergens>1.4.6.9.10.</Allergens>
							</Description>
						</p>
						<p>
							<Price>21.</Price>
							<Trans ns="menuLunch">bossa tagliatelle</Trans>.
							<Description>
								<Trans ns="menuLunch">burrata. walnut. celery. parmesan</Trans>.
								<Allergens>1.3.6.7.9.10.</Allergens>
							</Description>
						</p>
						<p>
							<Price>21.</Price>
							<Trans ns="menuLunch">smoked eel</Trans>.
							<Description>
								<Trans ns="menuLunch">
									crème fraîche. strawberry. beetroot. sechuan
								</Trans>
								.<Allergens>1.2.3.4.6.7.10.</Allergens>
							</Description>
						</p>
						<p>
							<Price>19.</Price>
							<Trans ns="menuLunch">oyster mushroom</Trans>.
							<Description>
								<Trans ns="menuLunch">chive. celeriac. balsamico. onion</Trans>.
								<Allergens>1.6.7.9.10.</Allergens>
							</Description>
						</p>
						<p>
							<Price>9.</Price>
							<Trans ns="menuLunch">soup of the day</Trans>.
						</p>
						<p>
							<Price>26./pp.</Price>
							<Trans ns="menuLunch">bossa moqueca</Trans>.
							<Description>
								<Trans ns="menuLunch">fish of the day. prawn. rice</Trans>.
								<Allergens>2.4.</Allergens>
								<br />
								<Trans ns="menuLunch">served for two</Trans>.
							</Description>
						</p> */}
						{/* <MenuTitle bold={t("bossa lunch")} descr="vive um mix" />
						<p className="selection">
							<Trans ns="menuLunch">chef selection</Trans>.
						</p>
						<p>
							<Trans ns="menuLunch">two course</Trans>.<Price>26.</Price>
						</p>
						<p>
							<Trans ns="menuLunch">three course</Trans>.<Price>35.</Price>
						</p> */}
						{/* <MenuTitle bold={t("salad")} descr="leve como a vida" />
						<p>
							<Price>19.</Price>
							<Trans ns="menuLunch">avocado</Trans>.
							<Description>
								<Trans ns="menuLunch">prawn. quinoa. olive</Trans>.
								<Allergens>1.2.6.12.</Allergens>
							</Description>
						</p>
						<p>
							<Price>14.</Price>
							<Trans ns="menuLunch">tomato</Trans>.
							<Description>
								<Trans ns="menuLunch">balsamico. red onion. basil</Trans>.
								<Allergens>1.3.6.8.10.</Allergens>
							</Description>
						</p>
						<p>
							<Price>12.</Price>
							<Trans ns="menuLunch">lettuce</Trans>.
							<Description>
								<Trans ns="menuLunch">radish. carrot. hazelnut</Trans>.
								<Allergens>3.7.8.10.12.</Allergens>
							</Description>
						</p> */}

						{/*<MenuTitle bold={t("tin to table")} />
						<p>
							<Trans ns="menuLunch">pyscis conserve</Trans>.
							<Description>
								<Trans ns="menuLunch">sourdough. homemade butter. lemon</Trans>.
								<Allergens>1.4.6.9.10.14.</Allergens>
							</Description>
							<Description>
								<span className="regular">
									<Price>13.</Price>
									<Trans ns="menuLunch">
										smoked sprat. / blue mussel. / sardine
									</Trans>
									.
								</span>
							</Description>
							
							<Description>
								<span className="regular">
									<Price>15.</Price>
									<Trans ns="menuLunch">razor clam</Trans>.
								</span>
							</Description>
						</p>*/}

						<MenuTitle bold={t("bossa kidz")} descr="cute bites" />
						<p>
							<Price>11.</Price>
							<Trans ns="menuLunch">homemade fries & nuggets</Trans>.
							<Description>
								<Trans ns="menuLunch">
									chicken. potato. ketchup. bossa mayo
								</Trans>
								.<Allergens>1.3.</Allergens>
							</Description>
						</p>
						<p>
							<Price>11.</Price>
							<Trans ns="menuLunch">tagliatelle</Trans>.
							<Description>
								<Trans ns="menuLunch">tomato sauce. parmesan</Trans>.
								<Allergens>1.3.7.</Allergens>
							</Description>
						</p>
						<p>
							<Price>11.</Price>
							<Trans ns="menuLunch">fried salmon</Trans>.
							<Description>
								<Trans ns="menuLunch">rice. broccoli</Trans>.
								<Allergens>1.4.7.</Allergens>
							</Description>
						</p>

						<MenuDessert />
						<p>
							<Price>7.</Price>
							<Trans ns="menuLunch">affogato</Trans>.
							<Description>
								<Trans ns="menuLunch">espresso. pistachio ice cream</Trans>.
								<Allergens>3.7.8.</Allergens>
							</Description>
						</p>
						<MenuCredits
							extras={false}
							section="lunch"
							fromTime={"12pm"}
							untilTime={"4pm"}
						/>
					</div>
				</section>
			</div>
		</MenuLayout>
	);
}

// graphql query
export const query = graphql`
	query ($language: String!) {
		locales: allLocale(filter: { language: { eq: $language } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
	}
`;
