// main imports
import * as React from "react";
import { graphql } from "gatsby";

// plugin imports
import { Trans, useI18next, useTranslation } from "gatsby-plugin-react-i18next";

// style imports
import "../../index.scss";
import "../menus.scss";

// svg imports

// components imports
import MenuLayout from "../../../components/Layout/MenuLayout.js";
import Seo from "../../../components/Seo/Seo.js";
import MenuTitle from "../../../components/Menus/MenuTitle";
import Price from "../../../components/Menus/Price";
import Description from "../../../components/Menus/Description";

// page
export default function IndexPage() {
	const { language } = useI18next();
	const { t } = useTranslation("menuJuicery");

	return (
		<MenuLayout>
			<Seo lang={language} title={t("juicery menu")} />
			<div className="bodyContainer menu">
				<section>
					<div>
						<h1>
							<Trans ns="menuJuicery">juicery menu</Trans>.
						</h1>
						
						<MenuTitle bold={t("shots")} descr="puros" />
						<p>
							<Price>4.</Price>
							<Trans ns="menuJuicery">ginger shot</Trans>.
							<Description>
								<Trans ns="menuJuicery">ginger. lemon. orange</Trans>.
							</Description>
						</p>
						
						<MenuTitle bold={t("bossa juicery")} descr="sucos" />
						<p>
							<Price>6.</Price>
							<Trans ns="menuJuicery">fresh orange</Trans>.
						</p>
						<p>
							<Price>7.</Price>
							<Trans ns="menuJuicery">fresh carrot</Trans>.
						</p>
						<p>
							<Price>8.</Price>
							<Trans ns="menuJuicery">orange</Trans>.
							<Description>
								<Trans ns="menuJuicery">orange juice. carrot. ginger</Trans>.
							</Description>
						</p>
						<p>
							<Price>8.</Price>
							<Trans ns="menuJuicery">green detox</Trans>.
							<Description>
								<Trans ns="menuJuicery">
									cucumber. ginger. celery. green apple. spinach
								</Trans>
								.
							</Description>
						</p>
						<p>
							<Price>8.</Price>
							<Trans ns="menuJuicery">red detox</Trans>.
							<Description>
								<Trans ns="menuJuicery">
									beetroot. red apple. orange juice
								</Trans>
								.
							</Description>
						</p>

						<MenuTitle bold={t("bossa shakes")} descr="vitaminas" />
						<p>
							<Price>8.</Price>
							<Trans ns="menuJuicery">banana</Trans>.
							<Description>
								<Trans ns="menuJuicery">
									milk. banana. peanut butter. caramel
								</Trans>
								.
							</Description>
						</p>
						<p>
							<Price>8.</Price>
							<Trans ns="menuJuicery">strawberry</Trans>.
							<Description>
								<Trans ns="menuJuicery">
									milk. strawberry. vanilla
								</Trans>
								.
							</Description>
						</p>
						<p>
							<Price>8.</Price>
							<Trans ns="menuJuicery">avocado</Trans>.
							<Description>
								<Trans ns="menuJuicery">milk. avocado. honey</Trans>.
							</Description>
						</p>
						<p>
							<Price>8.</Price>
							<Trans ns="menuJuicery">mango</Trans>.
							<Description>
								<Trans ns="menuJuicery">
									milk. mango. honey
								</Trans>
								.
							</Description>
						</p>


						<MenuTitle>
							<span>extras</span>
							<Price>+1.</Price>
						</MenuTitle>
					</div>
				</section>
			</div>
		</MenuLayout>
	);
}

// graphql query
export const query = graphql`
	query ($language: String!) {
		locales: allLocale(filter: { language: { eq: $language } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
	}
`;
