// main imports
import * as React from "react";
import { graphql } from "gatsby";

// plugin imports
import {
	Link,
	Trans,
	useI18next,
	useTranslation,
} from "gatsby-plugin-react-i18next";

// style imports
import "../index.scss";
import "./menus.scss";

// svg imports

// components imports
import MenuLayout from "../../components/Layout/MenuLayout.js";
import Seo from "../../components/Seo/Seo.js";

// page
export default function IndexPage() {
	const { t } = useTranslation();
	const { language } = useI18next();

	const menusList = [
		{
			name: t("breakfast menu"),
			link: "breakfast",
			text: t("from 09:00 to 12:00"),
		},
		{
			name: t("lunch menu"),
			link: "lunch",
			text: t("from 12pm to 04pm.\nsat and sun from 01pm to 04pm"),
		},
		{
			name: t("drinks menu"),
			link: "drinks",
			text: t("let's have a drink"),
		},
		{
			name: t("wine menu"),
			link: "wines",
			text: t("red. white. rose.."),
		},
		{
			name: t("spirits menu"),
			link: "spirits",
			text: t("something stronger"),
		},
		{
			name: t("juicery menu"),
			link: "juicery",
			text: t("a fresh new start"),
		},
		{
			name: t("coffee menu"),
			link: "coffee",
			text: t("stay caffeinated as always"),
		},
	];

	return (
		<MenuLayout hideMenu={true}>
			<Seo lang={language} title="menus" />
			<div className="bodyContainer menu">
				<section>
					<div>
						<h1>
							<Trans>our menus</Trans>.
						</h1>
						<div className="menuList separated">
							{menusList.map((menu) => {
								return (
									<div key={menu.name}>
										<Link to={`/menus/${menu.link}`} className="bold">
											{menu.name}
										</Link>
										.<span className="light"> {menu.text}.</span>
									</div>
								);
							})}
						</div>
					</div>
				</section>
			</div>
		</MenuLayout>
	);
}

// graphql query
export const query = graphql`
	query ($language: String!) {
		locales: allLocale(filter: { language: { eq: $language } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
	}
`;
