// main imports
import * as React from "react";
import { graphql } from "gatsby";

// plugin imports
import { Trans, useI18next, useTranslation } from "gatsby-plugin-react-i18next";

// style imports
import "../../index.scss";
import "../menus.scss";

// svg imports

// components imports
import MenuLayout from "../../../components/Layout/MenuLayout.js";
import Seo from "../../../components/Seo/Seo.js";
// import MenuTitle from "../../../components/Menus/MenuTitle";
// import Price from "../../../components/Menus/Price";

// page
export default function IndexPage() {
	const { language } = useI18next();
	const { t } = useTranslation("menuDrinks");

	return (
		<MenuLayout>
			<Seo lang={language} title={t("drinks menu")} />
			<div className="bodyContainer menu drinks">
				<section>
					<div>
						<h1>
							<Trans ns="menuDrinks">drinks menu</Trans>.
						</h1>

						{/* <MenuTitle bold={t("xmas special homemade")} />
						<p>
							<Price>4.5</Price>
							<Trans ns="menuDrinks">Punch</Trans>.
						</p>
						<p>
							<Price>4.&nbsp;</Price>
							<Trans ns="menuDrinks">Eggnog</Trans>.
						</p> */}

						<table className="twoCols">
							<thead>
								<tr>
									<th>
										<span>
											#<Trans ns="menuDrinks">non alcoholics</Trans>
										</span>
										.
									</th>
									<th aria-label="2col"></th>
									<th aria-label="3col"></th>
									<th aria-label="4col"></th>
								</tr>
							</thead>
							<tbody>
								{/* <tr>
									<td>
										Nestea <Trans ns="menuDrinks">can</Trans>.
									</td>
									<td>2.5</td>
									<td aria-label="Empty column"></td>
									<td>0.33l</td>
								</tr>
								<tr>
									<td>
										Fanta <Trans ns="menuDrinks">lemon</Trans>{" "}
										<Trans ns="menuDrinks">can</Trans>.
									</td>
									<td>2.5</td>
									<td aria-label="Empty column"></td>
									<td>0.33l</td>
								</tr>
								<tr>
									<td>
										Fanta <Trans ns="menuDrinks">orange</Trans>{" "}
										<Trans ns="menuDrinks">can</Trans>.
									</td>
									<td>2.5</td>
									<td aria-label="Empty column"></td>
									<td>0.33l</td>
								</tr>
								<tr>
									<td>
										Coca Cola Zero <Trans ns="menuDrinks">can</Trans>.
									</td>
									<td>2.5</td>
									<td aria-label="Empty column"></td>
									<td>0.33l</td>
								</tr>
								<tr>
									<td>
										Coca Cola <Trans ns="menuDrinks">can</Trans>.
									</td>
									<td>2.5</td>
									<td aria-label="Empty column"></td>
									<td>0.33l</td>
								</tr> */}
								<tr>
									<td>
										Guarana <Trans ns="menuDrinks">can</Trans>.
									</td>
									<td>4.</td>
									<td aria-label="Empty column"></td>
									<td>0.33l</td>
								</tr>
								<tr>
									<td>
										Almdudler <Trans ns="menuDrinks">can</Trans>.
									</td>
									<td>4.</td>
									<td aria-label="Empty column"></td>
									<td>0.33l</td>
								</tr>
								<tr>
									<td>
										Solan <Trans ns="menuDrinks">take plastic</Trans>.
									</td>
									<td>3.</td>
									<td aria-label="Empty column"></td>
									<td>0.50l</td>
								</tr>
								<tr>
									<td>
										<Trans ns="menuDrinks">still water</Trans> Solan.
									</td>
									<td>3.</td>
									<td aria-label="Empty column"></td>
									<td>0.50l</td>
								</tr>
								<tr>
									<td>
										<Trans ns="menuDrinks">sparkling Solan</Trans>.
									</td>
									<td>5.</td>
									<td aria-label="Empty column"></td>
									<td>0.75l</td>
								</tr>
								<tr>
									<td>
										<Trans ns="menuDrinks">soda water</Trans>.
									</td>
									<td>3.</td>
									<td aria-label="Empty column"></td>
									<td>0.20l</td>
								</tr>
								<tr>
									<td>Coca Cola.</td>
									<td>3.</td>
									<td aria-label="Empty column"></td>
									<td>0.20l</td>
								</tr>
								<tr>
									<td>Coca Cola Zero.</td>
									<td>3.</td>
									<td aria-label="Empty column"></td>
									<td>0.20l</td>
								</tr>
								<tr>
									<td>
										Fanta <Trans ns="menuDrinks">orange</Trans>.
									</td>
									<td>3.</td>
									<td aria-label="Empty column"></td>
									<td>0.20l</td>
								</tr>
								<tr>
									<td>
										Fanta <Trans ns="menuDrinks">lemon</Trans>.
									</td>
									<td>3.</td>
									<td aria-label="Empty column"></td>
									<td>0.20l</td>
								</tr>
								<tr>
									<td>ginger beer.</td>
									<td>3.</td>
									<td aria-label="Empty column"></td>
									<td>0.20l</td>
								</tr>
								<tr>
									<td>ginger ale.</td>
									<td>3.</td>
									<td aria-label="Empty column"></td>
									<td>0.20l</td>
								</tr>
								<tr>
									<td>Red Bull.</td>
									<td>4.</td>
									<td aria-label="Empty column"></td>
									<td>0.25l</td>
								</tr>
								{/* <tr>
									<td>
										Red Bull <Trans ns="menuDrinks">sugarfree</Trans>.
									</td>
									<td>4.</td>
									<td aria-label="Empty column"></td>
									<td>0.25l</td>
								</tr> */}
								<tr>
									<td>Sprite.</td>
									<td>3.</td>
									<td aria-label="Empty column"></td>
									<td>0.20l</td>
								</tr>
								<tr>
									<td>
										Pago <Trans ns="menuDrinks">apple</Trans>.
									</td>
									<td>3.5</td>
									<td aria-label="Empty column"></td>
									<td>0.20l</td>
								</tr>
								<tr>
									<td>
										Pago <Trans ns="menuDrinks">peach</Trans>.
									</td>
									<td>3.5</td>
									<td aria-label="Empty column"></td>
									<td>0.20l</td>
								</tr>
								<tr>
									<td>
										Pago <Trans ns="menuDrinks">pineapple</Trans>.
									</td>
									<td>3.5</td>
									<td aria-label="Empty column"></td>
									<td>0.20l</td>
								</tr>
								<tr>
									<td>
										Pago <Trans ns="menuDrinks">orange</Trans>.
									</td>
									<td>3.5</td>
									<td aria-label="Empty column"></td>
									<td>0.20l</td>
								</tr>
								<tr>
									<td>
										Pago <Trans ns="menuDrinks">cranberry</Trans>.
									</td>
									<td>3.5</td>
									<td aria-label="Empty column"></td>
									<td>0.20l</td>
								</tr>
								<tr>
									<td>
										<Trans ns="menuDrinks">ice tea</Trans>.
									</td>
									<td>3.</td>
									<td aria-label="Empty column"></td>
									<td>0.35l</td>
								</tr>
								<tr>
									<td>
										<Trans ns="menuDrinks">homemade lemonade</Trans>.
									</td>
									<td>6.</td>
									<td aria-label="Empty column"></td>
									<td>0.30l</td>
								</tr>
								{/* <tr>
									<td>Le Tribute.</td>
									<td>3.5</td>
									<td aria-label="Empty column"></td>
									<td>0.20l</td>
								</tr> */}
							</tbody>
						</table>

						<table>
							<thead>
								<tr>
									<th>
										<span>
											#<Trans ns="menuDrinks">beer</Trans>
										</span>
										.
									</th>
									<th>
										<Trans ns="menuDrinks">glass</Trans>
									</th>
									<th>
										<Trans ns="menuDrinks">bottle</Trans>
									</th>
									<th aria-label="4col"></th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>
										<Trans ns="menuDrinks">draft beer</Trans>.
									</td>
									<td>3.9</td>
									<td aria-label="Empty column"></td>
									<td>0.30l</td>
								</tr>
								<tr>
									<td>
										<Trans ns="menuDrinks">draft beer</Trans>.{" "}
										<Trans ns="menuDrinks">pint</Trans>.
									</td>
									<td>6.9</td>
									<td aria-label="Empty column"></td>
									<td>0.50l</td>
								</tr>
								{/* <tr>
									<td>Mahou Cinco Estrellas.</td>
									<td aria-label="Empty column"></td>
									<td>4.5</td>
									<td>0.33l</td>
								</tr> */}
								<tr>
									<td>Mahou 0.0%.</td>
									<td aria-label="Empty column"></td>
									<td>4.5</td>
									<td>0.33l</td>
								</tr>
								{/* <tr>
									<td>Mahou Classic.</td>
									<td aria-label="Empty column"></td>
									<td>4.5</td>
									<td>0.33l</td>
								</tr> */}
								<tr>
									<td>
										Mahou <Trans ns="menuDrinks">gluten-free</Trans>.
									</td>
									<td aria-label="Empty column"></td>
									<td>4.5</td>
									<td>0.33l</td>
								</tr>
								<tr>
									<td>Alhambra 1925.</td>
									<td aria-label="Empty column"></td>
									<td>4.5</td>
									<td>0.33l</td>
								</tr>
								{/* <tr>
									<td>Peroni.</td>
									<td aria-label="Empty column"></td>
									<td>4.5</td>
									<td>0.33l</td>
								</tr>
								<tr>
									<td>Franziskaner.</td>
									<td aria-label="Empty column"></td>
									<td>6.9</td>
									<td>0.50l</td>
								</tr> */}
								<tr>
									<td>Corona.</td>
									<td aria-label="Empty column"></td>
									<td>4.5</td>
									<td>0.35l</td>
								</tr>
								{/* <tr>
									<td>Stella Artois.</td>
									<td aria-label="Empty column"></td>
									<td>4.5</td>
									<td>0.33l</td>
								</tr> */}
							</tbody>
						</table>

						
					</div>
				</section>
			</div>
		</MenuLayout>
	);
}

// graphql query
export const query = graphql`
	query ($language: String!) {
		locales: allLocale(filter: { language: { eq: $language } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
	}
`;
