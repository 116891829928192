exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cafe-events-index-js": () => import("./../../../src/pages/cafe/events/index.js" /* webpackChunkName: "component---src-pages-cafe-events-index-js" */),
  "component---src-pages-cafe-index-js": () => import("./../../../src/pages/cafe/index.js" /* webpackChunkName: "component---src-pages-cafe-index-js" */),
  "component---src-pages-cafe-reserva-index-js": () => import("./../../../src/pages/cafe/reserva/index.js" /* webpackChunkName: "component---src-pages-cafe-reserva-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-index-js": () => import("./../../../src/pages/legal/index.js" /* webpackChunkName: "component---src-pages-legal-index-js" */),
  "component---src-pages-menus-breakfast-index-js": () => import("./../../../src/pages/menus/breakfast/index.js" /* webpackChunkName: "component---src-pages-menus-breakfast-index-js" */),
  "component---src-pages-menus-coffee-index-js": () => import("./../../../src/pages/menus/coffee/index.js" /* webpackChunkName: "component---src-pages-menus-coffee-index-js" */),
  "component---src-pages-menus-drinks-index-js": () => import("./../../../src/pages/menus/drinks/index.js" /* webpackChunkName: "component---src-pages-menus-drinks-index-js" */),
  "component---src-pages-menus-index-js": () => import("./../../../src/pages/menus/index.js" /* webpackChunkName: "component---src-pages-menus-index-js" */),
  "component---src-pages-menus-juicery-index-js": () => import("./../../../src/pages/menus/juicery/index.js" /* webpackChunkName: "component---src-pages-menus-juicery-index-js" */),
  "component---src-pages-menus-lunch-index-js": () => import("./../../../src/pages/menus/lunch/index.js" /* webpackChunkName: "component---src-pages-menus-lunch-index-js" */),
  "component---src-pages-menus-spirits-index-js": () => import("./../../../src/pages/menus/spirits/index.js" /* webpackChunkName: "component---src-pages-menus-spirits-index-js" */),
  "component---src-pages-menus-valentines-index-js": () => import("./../../../src/pages/menus/valentines/index.js" /* webpackChunkName: "component---src-pages-menus-valentines-index-js" */),
  "component---src-pages-menus-wines-index-js": () => import("./../../../src/pages/menus/wines/index.js" /* webpackChunkName: "component---src-pages-menus-wines-index-js" */),
  "component---src-pages-pousada-index-js": () => import("./../../../src/pages/pousada/index.js" /* webpackChunkName: "component---src-pages-pousada-index-js" */)
}

